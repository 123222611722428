<template>
    <Layout>
        <!-- <PageHeader :title="title"/> -->
        <div class="d-flex justify-content-between align-items-center mb-2">
            <LiveUsersTabs />
            <button
                :class="`btn btn-success ${loading ? 'disabled' : ''}`"
                @click="initList(1)">
                {{ $t('refresh') }}
            </button>
        </div>
        <div class="card data">
            <!-- <div class="card-header bg-light bg-soft filters">
                <ul class="nav nav-tabs flex-fill">
                    <li
                        class="nav-item"
                        v-for="s in user_options"
                        :key="s.value">
                        <a
                            :disabled="loading"
                            class="nav-link"
                            :class="{
                                active: filters.type === s.value,
                            }"
                            aria-current="page"
                            href="#"
                            @click="filters.type = s.value"
                            >{{ $t(s.name) }}</a
                        >
                    </li>
                </ul>
            </div> -->
            <div class="card-body mt-4">
                <div ref="tableScroller" class="table-responsive">
                    <LoadingTable
                        :cols="8"
                        :rows="50"
                        :td_small_class="`d-none`"
                        class="table-bordered table-striped"
                        v-if="loading" />
                    <table
                        v-else
                        class="table table-bordered table-hover table-striped table-nowrap mb-0">
                        <thead>
                            <tr class="fz">
                                <th scope="col" class="fz">#</th>
                                <th scope="col" class="fz">
                                    {{ $t('level') }}/ {{ $t('username') }}
                                </th>
                                <th scope="col">
                                    {{ $t('high-level store') }}
                                </th>
                                <th scope="col">
                                    {{ $t('game id') }}
                                </th>
                                <th scope="col">
                                    {{ $t('game company') }}
                                </th>
                                <th scope="col">
                                    {{ $t('game type') }}
                                </th>
                                <th scope="col">
                                    {{ $t('game money') }}
                                </th>
                                <th scope="col">
                                    {{ $t('commission') }}
                                </th>
                                <th scope="col">
                                    {{ $t('management') }}
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(r, i) in data.list" :key="r._id">
                                <td class="text-center">
                                    {{
                                        i +
                                        1 +
                                        (data.current_page - 1) * data.per_page
                                    }}
                                </td>
                                <td class="fz">
                                    <div class="td-username">
                                        <a
                                            href="javascript:void(0)"
                                            @click="
                                                goToTicketForm(
                                                    r.username,
                                                    r.role
                                                )
                                            ">
                                            <i
                                                class="bx bx-envelope bx-tada-hover fs-4"></i
                                        ></a>
                                        <a
                                            :title="$t(`click to view`)"
                                            href="javascript:void(0)"
                                            @click="goToUserForm(r._id, r.role)"
                                            class="link-username">
                                            <!-- :class="{
                                                'link-disabled':
                                                    r[`${r.role}_id`] == null,
                                            }" -->

                                            <i
                                                v-if="
                                                    r.role == 'player' &&
                                                    r.player.level
                                                "
                                                :class="`mdi mdi-numeric-${r.player.level}-box level-${r.player.level} fs-5`"></i>
                                            <!-- <i
                                                v-if="
                                                    r.role == 'agent' &&
                                                    r.agent.level
                                                "
                                                :class="`mdi mdi-numeric-${r.agent.level}-box level-${r.agent.level} fs-5`"></i> -->
                                                <span  v-if="r.role == 'agent' && r.agent.level" :class="`lvl lvl-${r[r.role]?.level}`">{{$t(`level ${r[r.role]?.level}`)}}</span>
                                            <i
                                                v-if="r.role == 'agent'"
                                                class="mdi mdi-account-tie text-danger fs-5"></i>
                                            <i
                                                v-if="r.role == 'player'"
                                                class="mdi mdi-account-cowboy-hat text-primary fs-5"></i>
                                            {{ r.username }}</a
                                        >
                                    </div>
                                </td>
                                <td class="text-center">
                                    <div
                                        class="d-flex align-items-center gap-1"
                                        v-if="r.agent_referrer">
                                        <i
                                            class="mdi mdi-account-tie text-danger fs-5"></i>
                                        <span class="mb-0 lh-1">
                                            {{ r.agent_referrer.username }}
                                        </span>
                                    </div>
                                    <div
                                        class="d-flex align-items-center gap-1"
                                        v-if="r.player_referrer">
                                        <i
                                            class="mdi mdi-account-cowboy-hat text-primary fs-5"></i>
                                        <span class="mb-0 lh-1">
                                            {{ r.player_referrer.username }}
                                        </span>
                                    </div>
                                </td>
                                <td class="text-center">
                                    {{
                                        r.last_bet
                                            ? r.last_bet.reference_debit
                                                  .username
                                            : null
                                    }}
                                </td>
                                <td class="text-center">
                                    <div v-if="r.last_bet">
                                        <span class="me-1">{{
                                            $t(r.last_bet.vendor)
                                        }}</span
                                        >({{
                                            $t(
                                                r.last_bet.provider ==
                                                    'honorlink'
                                                    ? 'HonorLink'
                                                    : r.last_bet.provider
                                            )
                                        }})
                                    </div>
                                </td>
                                <td class="text-center">
                                    <span
                                        v-if="r.last_bet"
                                        :class="{
                                            'text-success':
                                                r.last_bet.game == 'casino',
                                            'text-info':
                                                r.last_bet.game == 'slots',
                                        }"
                                        >{{ $t(r.last_bet.game) }}</span
                                    >
                                </td>

                                <td class="text-end">
                                    <!-- {{
                                        r.role == 'player'
                                            ? numberString(
                                                  r[r.role].assets.balance
                                              )
                                            : numberString(
                                                  r.agent.assets.balance
                                              )
                                    }} -->
                                    
                                    <div v-if="r[r.role].assets.honorlink && r[r.role].assets.honorlink > 0">
                                        {{ numberString(r[r.role].assets.honorlink) }}
                                    </div>
                                    <div v-else-if="r.transfer_in_game==true">
                                        {{ numberString(r[r.role].assets.honorlink) }}
                                    </div>
                                    <div v-else>
                                       {{ numberString(r[r.role].assets.balance) }}
                                    </div>
                                 
                                    
                                    <!-- <i class="mdi mdi-gold fs-4 text-warning"></i> -->
                                </td>
                                <td class="text-end">
                                    {{
                                        r.role == 'agent'
                                            ? numberStringDecimal(
                                                  r.agent.assets.commissions
                                              )
                                            : ''
                                    }}
                                    <!-- <i class="mdi mdi-gold fs-4 text-warning"></i> -->
                                </td>
                                <td class="text-center">
                                    <div class="button-items">
                                        <button
                                            class="btn btn-sm btn-outline-danger"
                                            @click="forceLogout(r)">
                                            {{ $t('force logout') }}
                                        </button>
                                        <button
                                            type="button"
                                            class="btn btn-warning btn-sm"
                                            @click="showCouponModal(r)">
                                            {{ $t('create coupon') }}
                                        </button>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>

                    <div
                        class="no-data-placeholder"
                        v-if="!data.list.length && !loading">
                        {{ $t('no data found') }}
                    </div>
                    <!-- <a
                                href="javascript:void(0);"
                                class="scroll-pad left"
                                @touchstart="startScrolling('left')"
                                @touchend="endScrolling"
                                @mousedown="startScrolling('left')"
                                @mouseup="endScrolling"
                                @mouseleave="endScrolling"
                            ><i class="bx bx-caret-left"></i></a>
                            <a
                                href="javascript:void(0);"
                                class="scroll-pad right"
                                @touchstart="startScrolling('right')"
                                @touchend="endScrolling"
                                @mousedown="startScrolling('right')"
                                @mouseup="endScrolling"
                                @mouseleave="endScrolling"
                            ><i class="bx bx-caret-right"></i></a> -->
                </div>

                <Pagination :data="data" @emitPage="initList" />
            </div>
        </div>
        <b-modal
            v-model="coupon_modal"
            centered
            hide-footer
            :title="`${$t(`coupon`)}`"
            @show="focusUsername"
            @hidden="reset">
            <b-form>
                <b-form-group
                    class="mb-3"
                    :label="$t('username')"
                    label-cols-sm="3">
                    <input
                        ref="username"
                        readonly
                        type="text"
                        class="form-control bg-white"
                        :class="{
                            'is-invalid':
                                pl_coupon.username &&
                                v$.pl_coupon.username.$error,
                            'is-valid':
                                pl_coupon.username &&
                                !v$.pl_coupon.username.$error,
                        }"
                        :placeholder="`${$t('enter username')}`"
                        v-model="pl_coupon.username"
                        list="usernames" />
                    <div
                        v-if="v$.pl_coupon.username.required.$invalid"
                        class="invalid-feedback">
                        {{ v$.pl_coupon.username.required.$message }}
                    </div>
                </b-form-group>
                <b-form-group
                    class="mb-3"
                    :label="$t('amount')"
                    label-cols-sm="3">
                    <VueNumberFormat
                        :disabled="v$.pl_coupon.username.$invalid"
                        class="form-control rounded-end"
                        :class="{
                            'is-invalid':
                                submitted && v$.pl_coupon.amount.$error,
                        }"
                        v-model:value="pl_coupon.amount"
                        :options="{
                            precision: 0,
                            prefix: '',
                            suffix: '',
                            thousand: ',',
                            acceptNegative: false,
                            isInteger: true,
                        }"></VueNumberFormat>
                    <div
                        v-for="(item, index) in v$.pl_coupon.amount.$errors"
                        :key="index"
                        class="invalid-feedback">
                        <span v-if="item.$message">
                            {{ $t(item.$message) }}
                        </span>
                    </div>
                </b-form-group>
                <b-form-group
                    class="mb-3"
                    :label="$t('code')"
                    label-cols-sm="3">
                    <div class="d-flex align-items-start gap-1">
                        <div class="flex-fill">
                            <input
                                readonly
                                :value="pl_coupon.code"
                                type="text"
                                class="form-control"
                                :class="{
                                    'is-invalid':
                                        submitted && v$.pl_coupon.code.$error,
                                    'bg-white': !v$.pl_coupon.username.$invalid,
                                }" />
                            <div
                                v-for="(item, index) in v$.pl_coupon.code
                                    .$errors"
                                :key="index"
                                class="invalid-feedback">
                                <span v-if="item.$message">{{
                                    $t(item.$message)
                                }}</span>
                            </div>
                        </div>
                        <button
                            :disabled="v$.pl_coupon.username.$invalid"
                            type="button"
                            class="btn"
                            :class="{
                                'btn-info': !v$.pl_coupon.username.$invalid,
                                'btn-light': v$.pl_coupon.username.$invalid,
                            }"
                            @click="generateCode()">
                            {{ $t('generate') }}
                        </button>
                    </div>
                </b-form-group>
                <b-form-group
                    class="mb-3"
                    :label="$t('expiration date')"
                    label-cols-sm="3">
                    <datepicker
                        :disabled="v$.pl_coupon.username.$invalid"
                        v-model="pl_coupon.expired_at"
                        :first-day-of-week="0"
                        :locale="$i18n.locale=='ko'?koLocale:null"
                        :lowerLimit="addDaysToDate(1)"
                        :class="`form-control ${
                            v$.pl_coupon.username.$invalid ? '' : 'bg-white'
                        }`"></datepicker>
                </b-form-group>
                <b-form-group
                    class="mb-3"
                    :label="$t('note')"
                    label-cols-sm="3">
                    <textarea
                        :disabled="v$.pl_coupon.username.$invalid"
                        v-model="pl_coupon.note"
                        class="form-control"
                        style="resize: none"
                        rows="5"
                        :class="{
                            'is-invalid': submitted && v$.pl_coupon.note.$error,
                        }"></textarea>
                    <div
                        v-for="(item, index) in v$.pl_coupon.note.$errors"
                        :key="index"
                        class="invalid-feedback">
                        <span v-if="item.$message">{{
                            $t(item.$message)
                        }}</span>
                    </div>
                </b-form-group>

                <button
                    class="btn btn-warning float-end"
                    @click.prevent="submitForm()"
                    :disabled="loading">
                    {{ $t('issue') }}
                </button>
            </b-form>
        </b-modal>
    </Layout>
</template>

<script>
import LiveUsersTabs from '@/components/live-users-tabs';
import Datepicker from 'vue3-datepicker';
import Layout from '../../../../layouts/main';
import PageHeader from '@/components/page-header';
import LoadingTable from '@/components/loading-table';
import Pagination from '../../../../../components/pagination.vue';
import Multiselect from '@vueform/multiselect';
import Switches from 'vue-switches';
import Swal from 'sweetalert2';
import { mapActions, mapGetters } from 'vuex';
import convert from '../../../../../mixins/convert';
import datetime from '../../../../../mixins/datetime';
import number from '../../../../../mixins/number';
import ui from '../../../../../mixins/ui';
import random from '../../../../../mixins/random';
import { required, minValue, maxValue, helpers } from '@vuelidate/validators';
import useVuelidate from '@vuelidate/core';
export default {
    setup() {
        return { v$: useVuelidate() };
    },
    components: {
        Layout,
        PageHeader,
        Pagination,
        Switches,
        LoadingTable,
        Multiselect,
        Datepicker,
        LiveUsersTabs,
    },
    mixins: [datetime, number, convert, ui, random],
    data() {
        return {
            filters: {
                type: 'all',
            },
            data: {
                list: [],
                links: [],
                current_page: 1,
                per_page: 1,
                last_page: 1,
                total: 0,
            },
            loading: false,
            user_options: [
                { name: this.$t('all'), value: 'all' },
                { name: this.$t('agent'), value: 'agent' },
                { name: this.$t('player'), value: 'player' },
            ],
            coupon_modal: false,
            pl_coupon: {
                _id: null,
                user_id: null,
                username: null,
                amount: null,
                code: null,
                expired_at: this.addDaysToDate(1),
                note: null,
            },
            submitted: false,
            intervalId: null,
            firstLoad: false,
        };
    },
    validations: {
        pl_coupon: {
            username: {
                required: helpers.withMessage('required', required),
                // validUsername: helpers.withMessage(
                //     'must be a valid username',
                //     function (value) {
                //         return this.pl_coupon.user_id != null;
                //     }
                // ),
            },
            amount: {
                required: helpers.withMessage('required', required),
                minValueValue: helpers.withMessage('required', minValue(1)),
                maxValueValue: helpers.withMessage(
                    'the maximum value is 9,999,999,999',
                    maxValue(9999999999)
                ),
            },
            code: {
                required: helpers.withMessage('required', required),
            },
            note: {
                required: helpers.withMessage('required', required),
            },
        },
    },
    watch: {
        filters: {
            async handler() {
                this.initList(1);
            },
            deep: true,
        },
        '$store.state.key'() {
            this.initList(1);
        },
    },
    methods: {
        ...mapActions('bet', {
            getLiveList: 'getLiveList',
        }),
        ...mapActions('coupon', {
            couponCreate: 'create',
        }),
        ...mapActions('player', {
            playerLogout: 'logout',
            playerExitGame: 'exitGame',
        }),
        ...mapActions('agent', {
            agentLogout: 'logout',
            agentExitGame: 'exitGame',
        }),
        async getListInterval() {
            await this.initList(1);
        },
        startInterval() {
            this.intervalId = setInterval(this.getListInterval, 10000);
        },
        stopInterval() {
            clearInterval(this.intervalId);
        },
        async initList(p) {
            this.resetScroll();
            if(!this.firstLoad)  this.loading = true;
            const data = await this.getLiveList({
                role: this.$route.params.role,
            });
            this.data.list = data.data;
            this.loading = false;
            this.firstLoad = true;
        },
         async forceLogout(r) {
            if (!this.isAuthorized()) return;
            var swal_html = `
                        <div>${this.$t('force logout')}</div>
                        <table class="table table-sm table-bordered fs-6 mt-3">
                            <tbody>
                                <tr>
                                    <th>${this.$t('level')}<t/h>
                                    <td>${
                                        r.role == 'player'?
                                        `<i class="mdi mdi-numeric-${r[r.role]?.level}-box fs-4  level-${r[r.role]?.level}"></i>`
                                        :`<span class="lvl lvl-${r[r.role]?.level}">${ this.$t(`level ${r[r.role]?.level}`)}</span>`
                                        }
                                    </td>
                                    </tr>
                                <tr>
                                    <th>${this.$t('username')}</th>
                                    <td>${r.username}</td>
                                </tr>
                            </tbody>
                        </table>
                    `;
            const confirmed = await Swal.fire({
                title: `${this.$t('are you sure')}?`,
                html: swal_html,
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#f1b44c',
                cancelButtonColor: '#74788d',
                cancelButtonText: this.$t('cancel'),
                confirmButtonText: this.$t('confirm'),
            }).then((result) => {
                return result.value;
            });

            if (confirmed) {
                await this[`${r.role}ExitGame`]({ id: r._id });
                const res = await this[`${r.role}Logout`]({ id: r._id });
                if (res) {
                    Swal.fire({
                        icon: res.success ? 'success' : 'error',
                        title: res.success
                            ? this.$t(`${r.role} has been logged out`)
                            : this.$t('an error occured'),
                        showConfirmButton: false,
                        timer: 2000,
                    });
                    if (res.success) {
                        this.initList(1);
                    }
                }
            }
        },
        showCouponModal(r) {
            this.pl_coupon.username = r.username;
            this.coupon_modal = true;
        },
        generateCode() {
            this.pl_coupon.code = this.randomCouponCode();
        },
        focusUsername() {
            setTimeout(() => {
                this.$nextTick(() => this.$refs.username.focus());
            }, 500);
        },
        reset() {
            this.submitted = false;
            this.coupon_modal = false;
            this.pl_coupon._id = null;
            this.pl_coupon.username = null;
            this.pl_coupon.amount = null;
            this.pl_coupon.code = null;
            this.pl_coupon.note = null;
            this.pl_coupon.expired_at = this.addDaysToDate(1);
            this.v$.pl_coupon.$reset();
        },
        async submitForm() {
            if (!this.isAuthorized()) return;
            this.submitted = true;
            this.v$.pl_coupon.$touch();
            if (this.v$.pl_coupon.$invalid) {
                return;
            } else {
                var fd = new FormData();
                fd.append('username', this.pl_coupon.username);
                fd.append('amount', this.pl_coupon.amount);
                fd.append('code', this.pl_coupon.code);
                fd.append(
                    'expired_at',
                    this.dateYmD(this.pl_coupon.expired_at) + ' 23:59:59'
                );
                fd.append('note', this.pl_coupon.note);
                this.loading = true;
                const res = await this.couponCreate({ form_data: fd });
                this.loading = false;
                if (res) {
                    Swal.fire({
                        title: this.$t(res.data.message),
                        icon: res.success ? 'success' : 'error',
                        timer: 2000,
                    });
                    if (res.success) {
                        this.reset();
                        this.initList();
                    }
                }
            }
        },
    },
    mounted() {
        this.initList(1);
        this.startInterval()
        this.$watch(
            () => this.$route.params,
            (n, o) => {
                if (this.$route.name == 'live-users') this.initList(1);
            }
        );
    },
    beforeUnmount() {
        this.stopInterval();
    },
};
</script>
